import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./App.css";

const expressions = ["🦒", "🔥", "🚗"];

function Home() {
  const [expression, setExpression] = useState(expressions[0]);
  const [clicked, setClicked] = useState(false);

  const changeExpression = () => {
    const randomExpression =
      expressions[Math.floor(Math.random() * expressions.length)];
    setExpression(randomExpression);
  };

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 1000);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="top-text">
          Welcome to the
          <br />
          <span className="font1"> Chimney </span>
          <span className="font2"> Ferrari </span>
          <span className="font3"> Giraffe </span>
          <br />
          World
        </h1>
        <h2 style={{ marginTop: "-10px" }} className="text-4">
          In a dimension where reality took a wrong turn and never looked
          back...
        </h2>
      </header>
      <img
        src="/logo.png"
        alt="Chimney Ferrari Giraffe Car"
        className="car-animation"
      />
      <div className="section-2">
        <Link to="/gallery">
          <button
            className="crazy-button"
            onMouseEnter={changeExpression}
            onClick={handleClick}
          >
            <span className="emoji">{expression}</span>
            <span className="text">Gallery</span>
          </button>
        </Link>
        <div className="star-wars-text">
          <p>
            A long time ago in a galaxy far, far away...
            <br />
            The Chimney Ferrari Giraffe was born out of pure chaos. Imagine a
            world where Ferraris are built for speed, giraffes roam freely with
            their elegant necks, and chimneys... well, no one really knows what
            chimneys are doing there, but they’re part of the mix anyway.
            <br />
            One fateful day, a Ferrari collided with a giraffe during a
            high-speed race to nowhere, and the two fused together in a freak
            accident of pure nonsense. But instead of a disaster, something
            amazing emerged: a sleek, red Ferrari with giraffe legs, zooming
            down the road with a chimney shooting out of its roof like it was
            meant to be there all along.
            <br />
            The Chimney Ferrari Giraffe became an instant legend, not for its
            practicality, but for its sheer ridiculousness. People everywhere
            couldn’t help but be captivated by this bizarre creation, and soon a
            memecoin was born in its honor. The Chimney Ferrari Giraffe memecoin
            became a cult hit, celebrated by those who appreciated the absurdity
            of life and knew that sometimes, the best things are the ones that
            make absolutely no sense at all.
          </p>
        </div>
        <div className="both">
          <div className="social-buttons">
            <a
              href="https://x.com/chimneyferrari"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
            >
              <img src="/twitter-logo.png" alt="Twitter" />
            </a>
            <a
              href="https://t.me/ChimneyFerrariGirafe"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
            >
              <img src="/telegram-logo.png" alt="Telegram" />
            </a>
            <a
              href="https://pump.fun/Gp9pMJEsW15KEv5tjQgwPt3NjrnwGanxnD9SUWxmpump"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
            >
              <img src="/pump-fun-logo.png" alt="Pump.fun" />
            </a>
          </div>
          <div className="footer-text">
            $CFG coin is a meme coin with no intrinsic value or expectation of
            financial return. I, the website creator @bodensmc, am not
            affiliated with the project in any way, just bought a bag and like
            the meme, it's too stupid not to run. Hmu if you want some changes
            on TG.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
