import React from "react";
import { Link } from "react-router-dom";
import "./App.css";

const images = [
  'anothergif.png', 'image1.png', 'image2.png', 'image3.png', 'image1.jpg', 'image2.jpg', 'image3.jpg', 'image4.jpg',
  'image9.webp', 'image10.webp', 'image11.webp', 'image12.webp', 'image13.webp', 'image14.webp', 'image15.webp',
  'image16.webp', 'image17.webp', 'image18.webp', 'image19.webp', 'image20.webp', 'image21.webp', 'image22.webp',
  'image23.webp', 'image24.webp', 'image25.webp', 'image26.webp', 'image27.webp', 'image28.webp', 'image29.webp',
  'image30.webp', 'image31.webp', 'image32.webp', 'image33.webp', 'image34.webp', 'image35.webp', 'image36.webp',
  'image37.webp', 'image38.webp', 'image39.webp', 'image40.webp', 'image41.webp', 'image42.webp'
];

function Home() {
  return (
    <div className="App-gallery">
      <header className="App-header-gallery">
        <h1 className="top-text">
          Here is our
          <br />
          <span className="font1"> Really </span>
          <span className="font2"> Dumb </span>
          <span className="font3"> Gallery </span>
          <br />
        </h1>
        <h2 style={{ marginTop: "-10px", marginBottom: '40px' }} className="text-4">
          Hope you enjoy
        </h2>
        <Link to="/">
          <button className="crazy-button">
            <span className="text">Return Home</span>
          </button>
        </Link>
      </header>
      
        <div className="photo-grid">
          {images.map((image, index) => (
            <div key={index} className="photo-grid-item">
              <img src={`/${image}`} alt={`Gallery ${index + 1}`} />
            </div>
          ))}
        </div>
        <div className="footer-text">
          $CFG coin is a meme coin with no intrinsic value or expectation of
          financial return. I, the website creator @bodensmc, am not
          affiliated with the project in any way, just bought a bag and like
          the meme, it's too stupid not to run. Hmu if you want some changes
          on TG.
        </div>
    </div>
  );
}

export default Home;
